import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/api/manage/biaoxun/list`,
    method: 'POST',
    params
  })
}


export function spiderOnlinePageAPI(data) {
  return http({
    url: `/api/manage/spider/online/spiderDetail`,
    method: 'POST',
    data
  })
}

export function delAPI(id) {
  return http({
    url: `/api/manage/biaoxun/del?id=${id}`,
    method: 'post',
    data: {}
  })
}
export function addAPI(data) {
  return http({
    url: `/api/manage/biaoxun/add`,
    method: 'post',
    data
  })
}

export function editAPI(data) {
  return http({
    url: `/api/manage/biaoxun/edit`,
    method: 'post',
    data
  })
}

export function getDetailAPI(id) {
  return http({
    url: `/api/manage/biaoxun/detail`,
    method: 'post',
    params: { id }
  })
}